















































import Vue from 'vue';
import _ from 'lodash';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { keycloak } from '@/plugins/keycloak-vue';
import { NS_ALERTS, NS_STATIONS } from '@/constants/app.constants';
import { Alert } from '@/models/states/alerts-state.model';
import { Permissions } from '@/services/permissions.service';
import Sidebar from '@/components/shared/Sidebar.component.vue';
import TimeZoneSelect from '@/components/shared/TimeZoneSelect.component.vue';
import { Session } from './services/session.service';

@Component({
  name: 'App',
  components: {
    Sidebar,
    TimeZoneSelect,
  },
})
export default class App extends Vue {
  @Getter('getAlerts', { namespace: NS_ALERTS }) alerts?: Alert[];
  @Action('fetchStations', { namespace: NS_STATIONS }) public fetchStations: any;
  @Action('fetchStationLocations', { namespace: NS_STATIONS }) public fetchStationLocations: any;
  @Action('fetchCustomers', { namespace: NS_STATIONS }) public fetchCustomers: any;

  public drawer: boolean = true;
  public pageTitle: string = '';
  public pageIcon: string = '';
  public customerId: string | null = null;

  public mounted() {
    this.fetchStations();
    this.fetchStationLocations();
    this.customerId = Session.getCustomerId();
    if (Permissions.isAdmin()) {
      this.fetchCustomers();
    }
    this.changeTitle();

    this.$router.afterEach(() => {
      this.changeTitle();
    });
  }

  public logout() {
    this.$router.replace(
      _.extend({}, this.$route, {
        query: {},
      }),
    );
    keycloak.logout();
  }

  private changeTitle() {
    this.pageTitle = _.get(this.$route.meta, 'title', '');
    this.pageIcon = _.get(this.$route.meta, 'icon', '');

    if (this.pageTitle) {
      document.title = `SeismicAI - ${this.pageTitle}`;
    } else {
      document.title = `SeismicAI`;
    }
  }
}

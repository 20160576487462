import * as ls from 'local-storage';
import { STORAGE_TIMEZONE } from '@/constants/app.constants';

class SettingsService {
  public timezone: string = '';

  constructor() {
    this.timezone = ls.get<string>(STORAGE_TIMEZONE) || 'GMT';
  }

  public setTimezone(timezone: string): void {
    this.timezone = timezone;
    ls.set<string>(STORAGE_TIMEZONE, timezone);
  }

  public getTimezone(): string {
    return this.timezone;
  }
}

export const Settings = new SettingsService();

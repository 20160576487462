import { RelativePosition, SeismicPosition } from '@/models/position.model';

export class GeometryUtils {
  public static normalizeLongitude(longitude: number): number {
    if (longitude < -170) {
      return longitude + 360;
    }

    return longitude;
  }

  public static normalizeUpLongitude(longitude: number): number {
    if (longitude > 178) {
      return longitude - 360;
    }

    return longitude;
  }

  public static convertAngleToRadians(angle: number): number {
    return (Math.PI / 180) * angle;
  }

  public static rotatePoint(lon: number, lat: number, azimuth: number, maxdist: number = 1000) {
    const glat1 = (lat * Math.PI) / 180;
    const glon1 = (lon * Math.PI) / 180;
    const s = maxdist / 1.852;
    const faz = (azimuth * Math.PI) / 180;

    const EPS = 0.00000000005;

    const a = 6378.13 / 1.852;
    const f = 1 / 298.257223563;
    const r = 1 - f;
    let tu = r * Math.tan(glat1);
    const sf = Math.sin(faz);
    const cf = Math.cos(faz);

    let b = cf === 0 ? 0 : 2 * Math.atan2(tu, cf);

    const cu = 1 / Math.sqrt(1 + tu * tu);
    const su = tu * cu;
    const sa = cu * sf;
    const c2a = 1 - sa * sa;
    let x = 1 + Math.sqrt(1 + c2a * (1 / (r * r) - 1));
    x = (x - 2) / x;
    let c = 1 - x;
    c = ((x * x) / 4 + 1) / c;
    let d = (0.375 * x * x - 1) * x;
    tu = s / (r * a * c);
    let y = tu;
    c = y + 1;

    let sy = 0;
    let cy = 0;
    let cz = 0;
    let e = 0;

    while (Math.abs(y - c) > EPS) {
      sy = Math.sin(y);
      cy = Math.cos(y);
      cz = Math.cos(b + y);
      e = 2 * cz * cz - 1;
      c = y;
      x = e * cy;
      y = e + e - 1;
      y = (((((sy * sy * 4 - 3) * y * cz * d) / 6 + x) * d) / 4 - cz) * sy * d + tu;
    }

    b = cu * cy * cf - su * sy;
    c = r * Math.sqrt(sa * sa + b * b);
    d = su * cy + cu * sy * cf;
    let glat2 = ((Math.atan2(d, c) + Math.PI) % (2 * Math.PI)) - Math.PI;
    c = cu * cy - su * sy * cf;
    x = Math.atan2(sy * sf, c);
    c = (((-3 * c2a + 4) * f + 4) * c2a * f) / 16;
    d = ((e * cy * c + cz) * sy * c + y) * sa;
    let glon2 = ((glon1 + x - (1 - c) * d * f + Math.PI) % (2 * Math.PI)) - Math.PI;

    let baz = (Math.atan2(sa, b) + Math.PI) % (2 * Math.PI);

    glon2 *= 180 / Math.PI;
    glat2 *= 180 / Math.PI;
    baz *= 180 / Math.PI;

    if (Math.abs(lon - glon2) > 180) {
      if (lon > glon2) {
        glon2 += 360;
      } else {
        glon2 -= 360;
      }
    }
    return [glon2, glat2];
  }

  // public static rotatePoint(x: number, y: number, bazAngle: number): number[] {
  //   const angle = (-bazAngle + 90 + 360) % 360;
  //   const angleToRadians = GeometryUtils.convertAngleToRadians(angle);
  //
  //   // start with a point that's just a segment on the Ox axis
  //   const p = {
  //     x: 10,
  //     y: 0,
  //   };
  //   const s = Math.sin(angleToRadians);
  //   const c = Math.cos(angleToRadians);
  //
  //   // rotate point
  //   const rotatedP = {
  //     x: p.x * c - p.y * s,
  //     y: p.x * s + p.y * c,
  //   };
  //
  //   // translate rotated point to the received target point:
  //   return [rotatedP.x + x, rotatedP.y + y];
  // }

  public static convertQualityToAngle(quality: number): number {
    if (quality === 0) {
      return 30;
    }
    if (quality === 1) {
      return 0;
    }
    return 25 * quality + 5;
  }

  public static toAbsoluteCoords(origin: SeismicPosition, relativePosition: RelativePosition): SeismicPosition | null {
    const missingCoordsIndicatorMagicValue = 9999.0;

    if (origin == null || relativePosition.x === missingCoordsIndicatorMagicValue || relativePosition.y === missingCoordsIndicatorMagicValue) {
      return null;
    }

    const newLat = origin.lat + relativePosition.y / 111.111111;
    const newLong = origin.long + relativePosition.x / (111.111111 * Math.cos(GeometryUtils.convertAngleToRadians((origin.lat + newLat) / 2)));

    return {
      lat: newLat,
      long: newLong,
    };
  }
}

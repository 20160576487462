import moment from 'moment-timezone';
import { DATE_HOUR_FORMAT } from '@/constants/app.constants';
import { FiltersSimulationState } from '@/models/states/filters-state.model';

const now = moment();
const timeStamp = now.subtract(300, 'seconds').clone();

export const state: FiltersSimulationState = {
  filters: {
    date: now.toISOString().substr(0, 10),
    displayInterval: 60,
    stationIds: null,
    time: timeStamp.clone().format(DATE_HOUR_FORMAT),
  },
};

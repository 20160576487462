













import moment from 'moment-timezone';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Options } from '@/models/app.model';
import { Settings } from '@/services/settings.service';

@Component({
  name: 'TimeZoneSelect',
})
export default class TimeZoneSelect extends Vue {
  @Prop() public model!: string[];

  public timezone: string = Settings.getTimezone();
  public displayIntervals: Options<number>[] = [];
  public timezones: string[] = [];

  public mounted() {
    this.timezones = moment.tz.names();
  }

  public onChange() {
    Settings.setTimezone(this.timezone);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
}

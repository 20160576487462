export function getIconFromRunStatus(status: string): string {
  if (status === 'COMPLETE') {
    return 'mdi-file';
  }
  if (status === 'ERROR') {
    return 'mdi-wrench';
  }
  return 'mdi-file-alert';
}

export function getColorFromRunStatus(status: string): string {
  if (status === 'COMPLETE') {
    return 'light-green lighten-1';
  }
  if (status === 'ERROR') {
    return 'red darken-1';
  }
  return 'orange lighten-2';
}

import Vue from 'vue';
import axios from 'axios';
import vuetify from '@/plugins/vuetify';
import { keycloak } from '@/plugins/keycloak-vue';
import { Session } from '@/services/session.service';
import { BASE_URL } from '@/constants/app.constants';
import router from './router';
import store from './store';
import '@/plugins/timezone';
import '@/plugins/highcharts-vue';
import '@/plugins/leaflet-vue';
import '@/plugins/validate-vue';
import '@/plugins/highlight-vue';
import '@/filters/index.filter';
import App from './App.vue';

Vue.config.productionTip = false;
axios.defaults.baseURL = BASE_URL;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 && error.response.data !== 'Unauthorized to view the specified stations') {
      return keycloak
        .updateToken(1800)
        .then(() => {
          Session.setToken(keycloak.token);
        })
        .catch((retryError) => {
          // Retry failed, clean up and reject the promise
          keycloak.logout();
          return Promise.reject(retryError);
        });
    }
    return Promise.reject(error);
  },
);

keycloak.init({ onLoad: 'login-required' }).then(() => {
  Session.setToken(keycloak.token);
  keycloak.loadUserInfo().then((user: any) => {
    Session.setCustomerId(user.customer_id);

    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  });
});

import { FiltersSimulationAnalysisState } from '@/models/states/filters-state.model';
import { MESSAGE_TYPES } from '@/constants/message-types.constant';

export const state: FiltersSimulationAnalysisState = {
  filters: {
    bpFilter: true,
    channels: null,
    messageTypes: [MESSAGE_TYPES.P_DETECTED, MESSAGE_TYPES.S_DETECTED, MESSAGE_TYPES.SOURCE, MESSAGE_TYPES.EVENT_ENDED],
    nodes: null,
    run: -1,
    stationIds: null,
    zoomEnd: 0,
    zoomStart: 0,
  },
};

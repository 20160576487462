import { MutationTree } from 'vuex';
import {
  FiltersRealtimeChannels,
  FiltersRealtimeChannelsState,
} from '@/models/states/filters-state.model';

export const mutations: MutationTree<FiltersRealtimeChannelsState> = {
  onUpdateFilters(state, payload: FiltersRealtimeChannels) {
    state.filters = payload;
  },
};

import _ from 'lodash';
import { GetterTree } from 'vuex';
import { RootState } from '@/models/states/root-state.model';
import { FiltersSimulation, FiltersSimulationState } from '@/models/states/filters-state.model';

export const getters: GetterTree<FiltersSimulationState, RootState> = {
  getFilters(state): FiltersSimulation {
    return _.cloneDeep(state.filters);
  },
};

import { ActionTree } from 'vuex';
import { RootState } from '@/models/states/root-state.model';
import { Station } from '@/models/station.model';
import { StationsState } from '@/models/states/stations-state.model';
import StationService from '@/services/station.service';
import CustomerService from '@/services/customer.service';

export const actions: ActionTree<StationsState, RootState> = {
  setSeismicEventInProgress({ commit }, payload: boolean): any {
    commit('onSetSeismicEventInProgress', payload);
  },

  fetchStations({ commit }): any {
    StationService.query().then(
      (response) => {
        commit('stationsLoaded', response.stations);
      },
      () => {},
    );
  },

  fetchStationLocations({ commit }): any {
    StationService.queryLocations().then(
      (stations) => {
        commit('stationLocationsLoaded', stations);
      },
      () => {},
    );
  },

  updateStation({ commit }, payload: { station: Station }): any {
    commit('stationUpdated', payload.station);
  },

  fetchCustomers({ commit }): any {
    CustomerService.query().then(
      (customers) => {
        commit('customersLoaded', customers);
      },
      () => {},
    );
  },

  fetchStation({ commit }, payload: { stationId: string }): any {
    commit('setCurrentStationId', payload.stationId);
    StationService.find(payload.stationId).then(
      (station) => {
        commit('stationLoaded', station);
      },
      () => {},
    );
  },

  fetchStationHealth({ commit }, payload: { stationId: string }): any {
    commit('setCurrentStationHealthId', payload.stationId);
    StationService.findHealth(payload.stationId).then(
      (stationHealth) => {
        commit('setStationHealth', stationHealth);
      },
      () => {
        commit('setStationHealthError');
      },
    );
  },
};

import _ from 'lodash';
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { Customer } from '@/models/customer.model';
import { Station, StationHealth } from '@/models/station.model';
import { StationsState } from '@/models/states/stations-state.model';

export const mutations: MutationTree<StationsState> = {
  onSetSeismicEventInProgress(state: StationsState, seismicEventInProgress: boolean) {
    state.seismicEventInProgress = seismicEventInProgress;
  },

  stationsLoaded(state: StationsState, stations: Station[]) {
    state.error = false;
    state.stations = stations;
  },

  stationLocationsLoaded(state: StationsState, stations: Station[]) {
    state.error = false;
    state.stationLocations = stations;
  },

  stationUpdated(state: StationsState, station: Station) {
    const { stations } = state;
    const index = _(stations).findIndex((s: Station) => s.stationId === station.stationId);

    if (index !== -1) {
      stations[index] = station;
    } else {
      stations.push(station);
    }

    Vue.set(state.stations, 'stations', stations);
  },

  customersLoaded(state: StationsState, customers: Customer[]) {
    state.customers = customers;
  },

  stationLoaded(state: StationsState, station: Station) {
    state.error = false;
    const existingStation = _(state.stations).find({ stationId: station.stationId });
    if (existingStation) {
      const index = _.findIndex(state.stations, existingStation);
      state.stations[index] = _.extend({}, existingStation, station);
    } else {
      state.stations.push(station);
    }
  },

  setCurrentStationId(state: StationsState, stationId: string) {
    state.currentStationId = stationId;
  },

  setCurrentStationHealthId(state: StationsState, stationId: string) {
    state.currentStationHealth = stationId;
    state.error = false;
  },

  setStationHealth(state: StationsState, stationHealth: StationHealth) {
    state.error = false;
    const update: any = {};
    update[stationHealth.stationId] = stationHealth;
    state.stationHealth = _.extend({}, state.stationHealth, update);
  },

  setStationHealthError(state: StationsState) {
    state.error = true;
  },
};

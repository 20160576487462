import * as ls from 'local-storage';
import _ from 'lodash';
import { STORAGE_OPEN_PANELS } from '@/constants/app.constants';
import { OpenPanelsState } from '@/models/states/open-panels-state';

const savedOpenPanels = ls.get<OpenPanelsState>(STORAGE_OPEN_PANELS) || {
  regionalEvent: {},
  event: {},
};

export const state: OpenPanelsState = _.cloneDeep(savedOpenPanels);

import _ from 'lodash';
import { MutationTree } from 'vuex';
import { Alert, AlertsState } from '@/models/states/alerts-state.model';

export const mutations: MutationTree<AlertsState> = {
  onAddAlert(state, payload: Alert) {
    state.alerts = _.union(state.alerts, [payload]);
  },
  removeAlert(state, payload: number) {
    state.alerts = _.reject(state.alerts, (alert: Alert) => alert.id === payload);
  },
  onClearAlerts(state) {
    state.alerts = [];
  },
};

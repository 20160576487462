import { Module } from 'vuex';
import { actions } from '@/store/filters-realtime/actions';
import { getters } from '@/store/filters-realtime/getters';
import { mutations } from '@/store/filters-realtime/mutations';
import { state } from '@/store/filters-realtime/state';
import { RootState } from '@/models/states/root-state.model';
import { FiltersRealtimeState } from '@/models/states/filters-state.model';

const namespaced: boolean = true;

export const filtersRealtime: Module<FiltersRealtimeState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

import axios from 'axios';
import _, { sortBy } from 'lodash';
import { ReadingsRequest, SOHQueryResponse, StationHealthQueryResponse, StationResponse } from '@/models/response.model';
import { Station, StationHealth, StationNote, StationNotesParams, StationSingleHealthType } from '@/models/station.model';
import { FiltersStationList } from '@/models/states/filters-state.model';
import { GeometryUtils } from '@/utils/geometry.utils';

export default class StationService {
  public static query(filters: Partial<FiltersStationList> = {}, filterOutEmptyStations: boolean = true): Promise<StationResponse> {
    return axios
      .post<StationResponse>(`/stations/query`, filters)
      .then((response) => response.data)
      .then((stations) => {
        const filteredStations = filterOutEmptyStations ? stations.stations.filter((station) => station.location) : stations.stations;
        return {
          stations: filteredStations.map((station) => {
            if (!station.healthStatusDetails) {
              return _.extend({}, station, {
                healthStatusDetails: {},
              });
            }
            return station;
          }),
        };
      });
  }

  public static queryLocations(filterOutEmptyStations: boolean = true): Promise<Station[]> {
    return axios
      .get('/stations/locations')
      .then((response) => response.data.stations)
      .then((stations) => (filterOutEmptyStations ? stations.filter((station: Station) => station.location) : stations.stations))
      .then((stations) => {
        return stations.map((station: any) => {
          const shouldNormalizeUp = station.region === 'AK' || station.region === 'AV';

          return {
            ...station,
            location: {
              ...station.location,
              long: shouldNormalizeUp
                ? GeometryUtils.normalizeUpLongitude(station.location.long)
                : GeometryUtils.normalizeLongitude(station.location.long),
            },
            healthStatusDetails: !station.healthStatusDetails ? {} : station.healthStatusDetails,
            regionId: station.region || 'Unknown',
          };
        });
      });
  }

  public static querySinglesHealth(customerIds: string[]): Promise<StationSingleHealthType[]> {
    return axios
      .post('/singlestation/health', {
        customerIds,
      })
      .then((response) => response.data)
      .then((stations) => {
        return sortBy(stations, ['stationId']).map((station: any) => {
          return {
            ...station,
            type: 'SINGLE',
            healthStatus: station.status,
            location: {
              ...station.location,
              long: GeometryUtils.normalizeUpLongitude(station.location.long),
            },
          };
        });
      });
  }

  public static notifyReporter(stationIds: string[]): Promise<Station[]> {
    return axios
      .post('/singlestation/health/send-health-email', {
        stations: stationIds,
      })
      .then((response) => response.data);
  }

  public static queryNotes(params: Partial<StationNotesParams>): Promise<StationNote[]> {
    return axios.post<StationNote[]>(`/stations/health/notes`, params).then((response) => response.data);
  }

  public static saveNote(stationId: string, note: string): Promise<StationNote> {
    return axios.post<StationNote>(`/stations/${stationId}/health/notes`, { note }).then((response) => response.data);
  }

  public static find(stationId: string): Promise<Station> {
    return axios.get<Station>(`/stations/${stationId}`).then((response) => response.data);
  }

  public static save(station: Partial<Station>): Promise<Station> {
    return axios.post<Station>(`/stations`, station).then((response) => response.data);
  }

  public static queryHealth(
    stationId: string,
    payload: { from?: number; timeGrouping?: { unit: string; value: number } } = {},
  ): Promise<StationHealthQueryResponse> {
    return axios.post<StationHealthQueryResponse>(`/stations/${stationId}/health/history/query`, payload).then((response) => response.data);
  }

  public static findHealth(stationId: string): Promise<StationHealth> {
    return axios.get<StationHealth>(`/stations/${stationId}/health`).then((response) => response.data);
  }

  public static querySOH(payload: Partial<ReadingsRequest>): Promise<SOHQueryResponse> {
    return axios.post<SOHQueryResponse>(`/soh/query`, payload).then((response) => response.data);
  }

  public static saveActiveState(stationId: string, state: boolean): Promise<Station> {
    return axios
      .post<Station>(`/stations/${stationId}/health/status`, {
        isActive: state,
        note: '',
      })
      .then((response) => response.data);
  }

  public static filterByRegion(stations: Station[], region: string): Station[] {
    return stations.filter((station) => station.regionId === region);
  }
}

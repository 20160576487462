import { GetterTree } from 'vuex';
import _ from 'lodash';
import { RootState } from '@/models/states/root-state.model';
import { StationsState } from '@/models/states/stations-state.model';
import { Station, StationHealth } from '@/models/station.model';
import { Customer } from '@/models/customer.model';

export const getters: GetterTree<StationsState, RootState> = {
  getSeismicEventInProgress(state): boolean {
    return state.seismicEventInProgress;
  },

  getAllStations(state): Station[] {
    return state.stations;
  },

  getStationLocations(state): Station[] {
    return state.stationLocations;
  },

  getCustomers(state): Customer[] {
    return state.customers;
  },

  getStationHealth(state): StationHealth {
    return state.stationHealth[state.currentStationHealth];
  },

  getStation(state): Station | undefined {
    return _(state.stations).find({ stationId: state.currentStationId });
  },

  getStationHealthError(state): boolean {
    return state.error;
  },
};

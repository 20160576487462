import _ from 'lodash';
import { GetterTree } from 'vuex';
import { RootState } from '@/models/states/root-state.model';
import { FiltersEventsRegional, FiltersEventsRegionalState } from '@/models/states/filters-state.model';

export const getters: GetterTree<FiltersEventsRegionalState, RootState> = {
  getFilters(state): FiltersEventsRegional {
    return _.cloneDeep(state.filters);
  },
};

import _ from 'lodash';
import { UNITS } from '@/constants/readings.constants';

export function textEllipsis(text: string, cutAt: number = 100): string {
  if (text.length > cutAt) {
    return `${_.take(text, cutAt).join('')}...`;
  }
  return text;
}

export function getUnit(unit: string): string {
  return _.get(_.find(UNITS, { value: unit }), 'unit', '');
}

import _ from 'lodash';

export const BASE_URL = _.get(window, 'seismicAiSettings.VUE_APP_API_BASE_URL', process.env.VUE_APP_API_BASE_URL);
export const AUTH_BASE_URL = _.get(window, 'seismicAiSettings.VUE_APP_AUTH_BASE_URL', process.env.VUE_APP_AUTH_BASE_URL);

export const MINUTES_ON_CHART = 15;
export const CLEAR_MAP_AFTER = 10 * 60; // seconds
export const REAL_TIME_POOL_INTERVAL = 4000; // miliseconds
export const REAL_TIME_POOL_HEALTH_INTERVAL = 4000; // miliseconds
export const REAL_TIME_POOL_STATE_INTERVAL = 60000; // miliseconds
export const MAX_SECONDS_TO_DISPLAY_READINGS = 300;
export const MAX_REAL_TIME_CHARTS_TO_SHOW = 3;

export const RED_COLOR = '#FF5722';
export const GREEN_COLOR = '#00C853';
export const ORANGE_COLOR = '#f57c00';
export const PURPLE_COLOR = '#8E24AA';
export const BLUE_COLOR = '#1E88E5';
export const LILAC_COLOR = '#b099e0';
export const CYAN_COLOR = '#26C6DA';
export const TEAL_COLOR = '#26A69A';
export const GREY_COLOR = '#ECEFF1';
export const MEDIUM_GREY_COLOR = '#9fa1a3';
export const DARK_GREY_COLOR = '#3b3b3b';

export const WEAK_COLOR = '#E58B44';
export const MEDIUM_COLOR = '#a70a0a';
export const STRONG_COLOR = '#8E24AA';

export const INTENSITY_COLORS = {
  0: '#FFFFFF',
  1: '#9A4DFF',
  2: '#6477FF',
  3: '#15B5DB',
  4: '#00BA54',
  5: '#98BC02',
  6: '#E5A300',
  7: '#EA7A00',
  8: '#F03D01',
  9: '#D10802',
  10: '#7F1418',
  11: '#361513',
};

export const ROLES: { [key: string]: string } = {
  ROLE_SIMULATION_RUNNER: 'ROLE_SIMULATION_RUNNER',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_BLOG_EDITOR: 'ROLE_BLOG_EDITOR',
};

export const SHAKE_MAP_COLORS = ['#C0D4EA', '#86C8E2', '#76C1D8', '#7BB676', '#D4D75B', '#F8CD4F', '#E58B44', '#CB3535', '#CB3535', '#85251C'];

export const NS_ALERTS: string = 'alerts';
export const NS_FILTERS: string = 'filters';
export const NS_FILTERS_REALTIME: string = 'filtersRealtime';
export const NS_FILTERS_EVENTS: string = 'filtersEvents';
export const NS_FILTERS_EVENTS_REGIONAL: string = 'filtersEventsRegional';
export const NS_FILTERS_CHANNELS: string = 'filtersRealtimeChannels';
export const NS_FILTERS_SIMULATION: string = 'filtersSimulation';
export const NS_FILTERS_SIMULATION_ANALYSIS: string = 'filtersSimulationAnalysis';
export const NS_POI: string = 'pointsOfInterest';
export const NS_SETTINGS: string = 'settings';
export const NS_STATIONS: string = 'stations';
export const NS_OPEN_PANELS: string = 'openPanels';

export const DATE_DEFAULT_FORMAT: string = 'YYYY-MM-DD';
export const DATE_SECONDARY_FORMAT: string = 'YYYY MMM DD';
export const DATE_HOUR_FORMAT: string = 'HH:mm:ss';
export const DATE_FULL_FORMAT: string = `${DATE_DEFAULT_FORMAT} ${DATE_HOUR_FORMAT}`;
export const DATE_SECONDARY_FULL_FORMAT: string = `${DATE_SECONDARY_FORMAT} ${DATE_HOUR_FORMAT}`;

export const STORAGE_OPEN_PANELS: string = 'seismic-ai-open-panels';
export const STORAGE_REALTIME_FILTERS: string = 'seismic-ai-realtime-filters';
export const STORAGE_TIMEZONE: string = 'seismic-ai-timezone';

export const TEST_STATIONS: string[] = ['01_AA_01'];

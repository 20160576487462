import { GetterTree } from 'vuex';
import _ from 'lodash';
import { RootState } from '@/models/states/root-state.model';
import { PointOfInterestState } from '@/models/states/point-of-interest-state';
import { PointOfInterest } from '@/models/position.model';

export const getters: GetterTree<PointOfInterestState, RootState> = {
  getAllPointsOfInterest(state): PointOfInterest[] {
    return state.pointsOfInterest;
  },

  getPointOfInterest(state): PointOfInterest | undefined {
    return _(state.pointsOfInterest).find({ id: state.currentId });
  },

  getPointsOfInterestQueryError(state): boolean {
    return state.queryError;
  },

  getPointsOfInterestLoading(state): boolean {
    return state.isLoading;
  },

  getPointsOfInterestSaveError(state): boolean {
    return state.saveError;
  },

  getPointsOfInterestSaveSuccessful(state): boolean {
    return state.saveSuccessful;
  },
};

import { AUTH_BASE_URL } from '@/constants/app.constants';
import Keycloak from 'keycloak-js';

export const keycloak = Keycloak({
  clientId: 'vader',
  realm: 'seismicai',
  url: AUTH_BASE_URL,

  // resource: 'vader',
  // 'auth-server-url': AUTH_BASE_URL,
  // 'ssl-required': 'none',
  // 'public-client': true,
  // 'confidential-port': 0,
});

import { NavigationGuardNext, Route } from 'vue-router';
import { Permissions } from '@/services/permissions.service';

export function simulationGuard(to: Route, from: Route, next: NavigationGuardNext) {
  if (Permissions.canRunSimulations()) {
    next();
  } else {
    next({ name: 'real-time-monitor' });
  }
}

export function rulesGuard(to: Route, from: Route, next: NavigationGuardNext) {
  if (Permissions.isAdmin()) {
    next();
  } else {
    next({ name: 'real-time-monitor' });
  }
}

export function stationConfigurationGuard(to: Route, from: Route, next: NavigationGuardNext) {
  if (Permissions.isAdmin()) {
    next();
  } else {
    next({ name: 'real-time-monitor' });
  }
}

import { Module } from 'vuex';
import { actions } from '@/store/alerts/actions';
import { getters } from '@/store/alerts/getters';
import { mutations } from '@/store/alerts/mutations';
import { state } from '@/store/alerts/state';
import { RootState } from '@/models/states/root-state.model';
import { AlertsState } from '@/models/states/alerts-state.model';

const namespaced: boolean = true;

export const alerts: Module<AlertsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

import { BLUE_COLOR, CYAN_COLOR, GREEN_COLOR, LILAC_COLOR, ORANGE_COLOR, PURPLE_COLOR, RED_COLOR, TEAL_COLOR } from '@/constants/app.constants';

interface MessageTypes {
  DETECTION_SUPPLEMENTARY: string;
  DETECTION_SUPPLEMENTARY_SINGLE: string;
  EVENT_ENDED: string;
  EVENT_ENDED_SINGLE: string;
  REGIONAL_EVENT_ENDED: string;
  P_DETECTED: string;
  P_DETECTED_SINGLE: string;
  P_SLOBAZ_DETERMINED: string;
  P_REFINED: string;
  S_DETECTED: string;
  S_DETECTED_SINGLE: string;
  SOH: string;
  SOURCE: string;
  ALERT: string;
  VIDA: string;
}

export const MESSAGE_TYPES: MessageTypes = {
  DETECTION_SUPPLEMENTARY: 'DETECTION_SUPPLEMENTARY',
  DETECTION_SUPPLEMENTARY_SINGLE: 'DETECTION_SUPPLEMENTARY_SINGLE',
  EVENT_ENDED: 'EVENT_ENDED',
  EVENT_ENDED_SINGLE: 'EVENT_ENDED_SINGLE',
  REGIONAL_EVENT_ENDED: 'REGIONAL_EVENT_ENDED',
  P_DETECTED: 'P_DETECTED',
  P_DETECTED_SINGLE: 'P_DETECTED_SINGLE',
  P_SLOBAZ_DETERMINED: 'P_SLOBAZ_DETERMINED',
  P_REFINED: 'P_REFINED',
  S_DETECTED: 'S_DETECTED',
  S_DETECTED_SINGLE: 'S_DETECTED_SINGLE',
  SOH: 'SOH',
  SOURCE: 'SOURCE',
  ALERT: 'ALERT',
  VIDA: 'VIDA',
};

export const ALL_MESSAGE_TYPES: string[] = [
  MESSAGE_TYPES.DETECTION_SUPPLEMENTARY,
  MESSAGE_TYPES.EVENT_ENDED,
  MESSAGE_TYPES.REGIONAL_EVENT_ENDED,
  MESSAGE_TYPES.P_DETECTED,
  MESSAGE_TYPES.P_SLOBAZ_DETERMINED,
  MESSAGE_TYPES.P_REFINED,
  MESSAGE_TYPES.S_DETECTED,
  MESSAGE_TYPES.SOURCE,
  MESSAGE_TYPES.VIDA,
];

export const MESSAGE_TYPES_COLORS: MessageTypes = {
  DETECTION_SUPPLEMENTARY: TEAL_COLOR,
  DETECTION_SUPPLEMENTARY_SINGLE: TEAL_COLOR,
  EVENT_ENDED: PURPLE_COLOR,
  EVENT_ENDED_SINGLE: PURPLE_COLOR,
  REGIONAL_EVENT_ENDED: PURPLE_COLOR,
  P_DETECTED: GREEN_COLOR,
  P_DETECTED_SINGLE: GREEN_COLOR,
  P_SLOBAZ_DETERMINED: BLUE_COLOR,
  P_REFINED: LILAC_COLOR,
  S_DETECTED: RED_COLOR,
  S_DETECTED_SINGLE: RED_COLOR,
  SOH: CYAN_COLOR,
  SOURCE: ORANGE_COLOR,
  ALERT: ORANGE_COLOR,
  VIDA: TEAL_COLOR,
};

export const START_EVENTS = [
  MESSAGE_TYPES.P_DETECTED,
  MESSAGE_TYPES.S_DETECTED,
  MESSAGE_TYPES.SOURCE,
  MESSAGE_TYPES.P_SLOBAZ_DETERMINED,
  MESSAGE_TYPES.P_REFINED,
];

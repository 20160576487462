import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from '@/models/states/root-state.model';
import { alerts } from '@/store/alerts';
import { filters } from '@/store/filters-analysis';
import { filtersEvents } from '@/store/filters-events';
import { filtersEventsRegional } from '@/store/filters-regional';
import { filtersRealtime } from '@/store/filters-realtime';
import { filtersRealtimeChannels } from '@/store/filters-realtime-channels';
import { filtersSimulation } from '@/store/filters-simulation';
import { filtersSimulationAnalysis } from '@/store/filters-simulation-analysis';
import { pointsOfInterest } from '@/store/points-of-interest';
import { readings } from '@/store/readings';
import { stations } from '@/store/stations';
import { openPanels } from '@/store/open-panels';

Vue.use(Vuex);

const rootStore: StoreOptions<RootState> = {
  state: {
    version: '1.0.0',
  },
  modules: {
    alerts,
    filters,
    filtersEvents,
    filtersEventsRegional,
    filtersRealtime,
    filtersRealtimeChannels,
    filtersSimulation,
    filtersSimulationAnalysis,
    openPanels,
    pointsOfInterest,
    readings,
    stations,
  },
};

export default new Vuex.Store<RootState>(rootStore);

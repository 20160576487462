import { Module } from 'vuex';
import { actions } from '@/store/points-of-interest/actions';
import { getters } from '@/store/points-of-interest/getters';
import { mutations } from '@/store/points-of-interest/mutations';
import { state } from '@/store/points-of-interest/state';
import { RootState } from '@/models/states/root-state.model';
import { PointOfInterestState } from '@/models/states/point-of-interest-state';

const namespaced: boolean = true;

export const pointsOfInterest: Module<PointOfInterestState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

import _ from 'lodash';
import { GetterTree } from 'vuex';
import { RootState } from '@/models/states/root-state.model';
import { FiltersSimulationAnalysis, FiltersSimulationAnalysisState } from '@/models/states/filters-state.model';

export const getters: GetterTree<FiltersSimulationAnalysisState, RootState> = {
  getFilters(state): FiltersSimulationAnalysis {
    return _.cloneDeep(state.filters);
  },
};

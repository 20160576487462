import _ from 'lodash';
import { MutationTree } from 'vuex';
import { ReadingsState } from '@/models/states/readings-state.model';
import { Reading } from '@/models/reading.model';

export const mutations: MutationTree<ReadingsState> = {
  readingAdded(state, payload: Reading) {
    const oldData = _.cloneDeep(state.data);
    oldData.push([payload.time, payload.value]);
    if (oldData.length > 150) {
      oldData.splice(0, 1);
    }
    state.data = oldData;
  },
};

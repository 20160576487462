import _ from 'lodash';
import { GetterTree } from 'vuex';
import { RootState } from '@/models/states/root-state.model';
import { FiltersRealtime, FiltersRealtimeState } from '@/models/states/filters-state.model';

export const getters: GetterTree<FiltersRealtimeState, RootState> = {
  getFilters(state): FiltersRealtime {
    return _.cloneDeep(state.filters);
  },
};

import { Module } from 'vuex';
import { ReadingsState } from '@/models/states/readings-state.model';
import { actions } from '@/store/readings/actions';
import { getters } from '@/store/readings/getters';
import { mutations } from '@/store/readings/mutations';
import { state } from '@/store/readings/state';
import { RootState } from '@/models/states/root-state.model';

const namespaced: boolean = true;

export const readings: Module<ReadingsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

import { ActionTree } from 'vuex';
import { RootState } from '@/models/states/root-state.model';
import { PointOfInterestState } from '@/models/states/point-of-interest-state';
import POIService from '@/services/poi.service';
import { PointOfInterest } from '@/models/position.model';

export const actions: ActionTree<PointOfInterestState, RootState> = {
  fetchPointsOfInterest({ commit }): any {
    commit('setLoading', true);
    POIService.query().then(
      (pointsOfInterest) => {
        commit('setLoading', false);
        commit('pointsOfInterestLoaded', pointsOfInterest);
      },
      () => {},
    );
  },

  fetchPointOfInterest({ commit }, payload: { id: number }): any {
    commit('setCurrentId', payload.id);
    POIService.find(payload.id).then(
      (pointOfInterest) => {
        commit('pointOfInterestLoaded', pointOfInterest);
      },
      () => {},
    );
  },

  removePointOfInterest({ commit }, payload: { id: number }): any {
    commit('setLoading', true);
    POIService.remove(payload.id).then(
      () => {
        commit('setLoading', false);
        commit('pointOfInterestRemoved', payload.id);
      },
      () => {},
    );
  },

  savePointOfInterest({ commit }, payload: PointOfInterest): any {
    commit('setLoading', true);
    POIService.save(payload).then(
      (newPointOfInterest) => {
        commit('setSaveSuccessful', true);
        if (payload.id) {
          commit('pointOfInterestUpdated', newPointOfInterest);
        } else {
          commit('pointOfInterestAdded', newPointOfInterest);
        }
      },
      () => {
        commit('setSaveError', true);
      },
    );
  },
};

import _ from 'lodash';
import { MutationTree } from 'vuex';
import { PointOfInterestState } from '@/models/states/point-of-interest-state';
import { PointOfInterest } from '@/models/position.model';

export const mutations: MutationTree<PointOfInterestState> = {

  pointsOfInterestLoaded(state: PointOfInterestState, pointsOfInterest: PointOfInterest[]) {
    state.queryError = false;
    state.pointsOfInterest = pointsOfInterest;
  },

  pointOfInterestRemoved(state: PointOfInterestState, pointsOfInterestId: number) {
    state.queryError = false;
    state.pointsOfInterest = _(state.pointsOfInterest).reject({ id: pointsOfInterestId }).value();
  },

  pointOfInterestAdded(state: PointOfInterestState, pointsOfInterest: PointOfInterest) {
    state.isLoading = false;
    state.saveSuccessful = true;
    state.saveError = false;
    state.pointsOfInterest = _(state.pointsOfInterest).union([ pointsOfInterest ]).sortBy(['name']).value();
  },

  pointOfInterestUpdated(state: PointOfInterestState, pointsOfInterest: PointOfInterest) {
    state.isLoading = false;
    state.saveSuccessful = true;
    state.saveError = false;
    state.pointsOfInterest = _(state.pointsOfInterest).reject({ id: pointsOfInterest.id }).union([ pointsOfInterest ]).sortBy(['name']).value();
  },

  pointOfInterestLoaded(state: PointOfInterestState, pointOfInterest: PointOfInterest) {
    state.queryError = false;
    const existing = _(state.pointsOfInterest).find({ id: pointOfInterest.id });
    if (existing) {
      const index = _.findIndex(state.pointsOfInterest, existing);
      state.pointsOfInterest[index] = _.extend({}, existing, pointOfInterest);
    } else {
      state.pointsOfInterest.push(pointOfInterest);
    }
  },

  setLoading(state: PointOfInterestState, loading: boolean) {
    state.isLoading = loading;
    if ( loading ) {
      state.saveSuccessful = false;
      state.saveError = false;
    }
  },

  setCurrentId(state: PointOfInterestState, id: number) {
    state.currentId = id;
  },

  setSaveError(state: PointOfInterestState, error: boolean) {
    state.isLoading = false;
    state.saveSuccessful = false;
    state.saveError = error;
  },

  setSaveSuccessful(state: PointOfInterestState, successful: boolean) {
    state.isLoading = false;
    state.saveSuccessful = successful;
    state.saveError = false;
  },
};

import { StationsState } from '@/models/states/stations-state.model';

export const state: StationsState = {
  customers: [],
  error: false,
  seismicEventInProgress: false,
  stations: [],
  stationLocations: [],
  stationHealth: {},
  currentStationHealth: '',
  currentStationId: '',
};

import { FiltersRealtimeChannelsState } from '@/models/states/filters-state.model';
import { UNIT } from '@/constants/readings.constants';

export const state: FiltersRealtimeChannelsState = {
  filters: {
    bpFilter: false,
    displayInterval: 5,
    channels: [[3, 4, 5]],
    nodes: null,
    unit: UNIT.SENSOR_SPECIFIC,
  },
};

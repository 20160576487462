





























import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { routes } from '@/router';
import { Permissions } from '@/services/permissions.service';

interface SidebarItem {
  linkTo: string;
  icon: string;
  title: string;
  children: SidebarItem[];
}

@Component({
  name: 'Sidebar',
})
export default class Sidebar extends Vue {
  public items: SidebarItem[] = [];

  public mounted() {
    this.items = _.compact([
      { linkTo: 'real-time-monitor', icon: this.getIcon('real-time-monitor'), title: this.getTitle('real-time-monitor'), children: [] },
      {
        linkTo: 'real-time-readings-monitor',
        icon: this.getIcon('real-time-readings-monitor'),
        title: this.getTitle('real-time-readings-monitor'),
        children: [],
      },
      { linkTo: 'seismograms', icon: this.getIcon('seismograms'), title: this.getTitle('seismograms'), children: [] },
      { linkTo: 'events-list', icon: this.getIcon('events-list'), title: this.getTitle('events-list'), children: [] },
      Permissions.canRunSimulations()
        ? { linkTo: 'simulation-list', icon: this.getIcon('simulation-list'), title: this.getTitle('simulation-list'), children: [] }
        : null,
      {
        linkTo: 'stations',
        icon: 'mdi-axis-arrow',
        title: 'Stations',
        children: _.compact([
          { linkTo: 'stations', icon: this.getIcon('stations'), title: this.getTitle('stations', true), children: [] },
          { linkTo: 'station-monitor', icon: this.getIcon('station-monitor'), title: this.getTitle('station-monitor', true), children: [] },
          {
            linkTo: 'single-stations-monitor',
            icon: this.getIcon('single-stations-monitor'),
            title: this.getTitle('single-stations-monitor', true),
            children: [],
          },
          Permissions.isAdmin()
            ? {
                linkTo: 'station-configuration-history',
                icon: this.getIcon('station-configuration-history'),
                title: this.getTitle('station-configuration-history', true),
                children: [],
              }
            : null,
          Permissions.isAdmin()
            ? { linkTo: 'service-versions', icon: this.getIcon('service-versions'), title: this.getTitle('service-versions'), children: [] }
            : null,
          Permissions.isAdmin()
            ? {
                linkTo: 'station-service-configuration',
                icon: this.getIcon('station-service-configuration'),
                title: this.getTitle('station-service-configuration', true),
                children: [],
              }
            : null,
        ]),
      },
      { linkTo: 'points-of-interest', icon: this.getIcon('points-of-interest'), title: this.getTitle('points-of-interest'), children: [] },
      Permissions.isAdmin() ? { linkTo: 'rules-list', icon: this.getIcon('rules-list'), title: this.getTitle('rules-list'), children: [] } : null,
    ]);
  }

  public getTitle(routeName: string, useShortTitle: boolean = false) {
    return _.get(_(routes).find({ name: routeName }), useShortTitle ? 'meta.shortTitle' : 'meta.title', '');
  }

  public getIcon(routeName: string) {
    return _.get(_(routes).find({ name: routeName }), 'meta.icon', '');
  }
}

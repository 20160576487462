import numeral from 'numeral';

export function roundToDecimal(value: number, decimals: number = 2): string {
  const decimalFormat = Array.from(Array(decimals).keys())
    .map(() => '0')
    .join('');
  return value ? numeral(value).format(`0.${decimalFormat}`) : '--';
}

export function scientificNotation(value: number): string {
  return value ? numeral(value).format(`0.000e+0`) : '--';
}

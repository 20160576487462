import Vue from 'vue';
import { LControlLayers, LControlScale, LControlZoom, LMap, LMarker, LTileLayer } from 'vue2-leaflet';
import { Icon } from 'leaflet';
import LControlPolylineMeasure from 'vue2-leaflet-polyline-measure';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-control-scale', LControlScale);
Vue.component('l-control-layers', LControlLayers);
Vue.component('l-control-zoom', LControlZoom);
// @ts-ignore
Vue.component('l-control-polyline-measure', LControlPolylineMeasure);

// @ts-ignore
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconSize: [32, 32],
  iconAnchor: [16, 28],
  tooltipAnchor: [0, -5],
  iconRetinaUrl: require('../assets/marker-default.png'),
  iconUrl: require('../assets/marker-default.png'),
  shadowUrl: null,
});

import Vue from 'vue';
import { getDateTime, getTime } from '@/filters/time.filter';
import { getColorFromStatus, getIconFromStatus } from '@/filters/status.filter';
import { getNameByChannelId } from '@/filters/channel.filter';
import { getColorFromRunStatus, getIconFromRunStatus } from '@/filters/status-run.filter';
import { roundToDecimal, scientificNotation } from '@/filters/number.filter';
import { getUnit, textEllipsis } from '@/filters/text.filter';

Vue.filter('getDateTime', getDateTime);
Vue.filter('getTime', getTime);
Vue.filter('getIconFromStatus', getIconFromStatus);
Vue.filter('getColorFromStatus', getColorFromStatus);
Vue.filter('getIconFromRunStatus', getIconFromRunStatus);
Vue.filter('getColorFromRunStatus', getColorFromRunStatus);
Vue.filter('getNameByChannelId', getNameByChannelId);
Vue.filter('roundToDecimal', roundToDecimal);
Vue.filter('scientificNotation', scientificNotation);
Vue.filter('textEllipsis', textEllipsis);
Vue.filter('getUnit', getUnit);

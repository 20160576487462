import * as ls from 'local-storage';
import _ from 'lodash';
import { FiltersRealtime, FiltersRealtimeState } from '@/models/states/filters-state.model';
import { STORAGE_REALTIME_FILTERS } from '@/constants/app.constants';
import { UNIT } from '@/constants/readings.constants';

const filtersRealtime: FiltersRealtimeState = {
  filters: ls.get<FiltersRealtime>(STORAGE_REALTIME_FILTERS) || {
    bpFilter: false,
    displayInterval: 5,
    stationIds: null,
    unit: UNIT.SENSOR_SPECIFIC,
  },
};

export const state: FiltersRealtimeState = _.cloneDeep(filtersRealtime);

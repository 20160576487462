export function getNameByChannelId(channelId: number): string {
  switch (channelId) {
    case 0:
      return 'Acceleration Z';
    case 1:
      return 'Acceleration E';
    case 2:
      return 'Acceleration N';
    case 3:
      return 'Velocity Z';
    case 4:
      return 'Velocity E';
    case 5:
      return 'Velocity N';
    default:
      return `Channel ${channelId}`;
  }
}

export function getNameByChannelIdArray(channels: number[]) {
  const channelStrings = channels.map((channel) => getNameByChannelId(channel));
  return channelStrings
    .join(', ')
    .replace('Acceleration Z, Acceleration E, Acceleration N', 'Acceleration')
    .replace('Velocity Z, Velocity E, Velocity N', 'Velocity');
}

import moment from 'moment-timezone';
import { DATE_FULL_FORMAT, DATE_HOUR_FORMAT } from '@/constants/app.constants';

export function getDateTime(unix: number, format: string = DATE_FULL_FORMAT): string {
  return unix ? moment(unix).format(format) : '--';
}

export function getTime(time: number, format: string = DATE_HOUR_FORMAT): string {
  return time ? moment(time).format(format) : '--';
}

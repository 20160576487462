import { Icon } from 'leaflet';
import { AlertLevelType } from '@/constants/alert-level.constants';
import { StationHealthStatus, StationType } from '@/constants/station.constants';

const stationArrayDefaultPng = require('@/assets/map-icons/station-array-default.png');
const stationArrayWarningPng = require('@/assets/map-icons/station-array-warning.png');
const stationArrayUnhealthyPng = require('@/assets/map-icons/station-array-unhealthy.png');
const stationArrayTriggeredPng = require('@/assets/map-icons/station-array-triggered.png');

const stationNodeDefaultPng = require('@/assets/map-icons/station-node-default.png');
const stationNodeWarningPng = require('@/assets/map-icons/station-node-warning.png');
const stationNodeUnhealthyPng = require('@/assets/map-icons/station-node-unhealthy.png');
const stationNodeTriggeredPng = require('@/assets/map-icons/station-node-triggered.png');

const stationSingleDefaultPng = require('@/assets/map-icons/station-single-default.png');
const stationSingleWarningPng = require('@/assets/map-icons/station-single-warning.png');
const stationSingleUnhealthyPng = require('@/assets/map-icons/station-single-unhealthy.png');
const stationSingleTriggeredPng = require('@/assets/map-icons/station-single-triggered.png');

const epicenterPng = require('@/assets/map-icons/epicenter.png');
const catalogLocationPng = require('@/assets/map-icons/catalog-location.png');
const poiDefaultPng = require('@/assets/map-icons/poi-default.png');
const poiWeakPng = require('@/assets/map-icons/poi-weak.png');
const poiMediumPng = require('@/assets/map-icons/poi-medium.png');
const poiStrongPng = require('@/assets/map-icons/poi-strong.png');

export const iconStationArray = new Icon({
  iconSize: [32, 32],
  tooltipAnchor: [0, 0],
  iconRetinaUrl: stationArrayDefaultPng,
  iconUrl: stationArrayDefaultPng,
  shadowUrl: '',
});

export const iconStationArrayWarning = new Icon({
  iconSize: [32, 32],
  tooltipAnchor: [0, 0],
  iconRetinaUrl: stationArrayWarningPng,
  iconUrl: stationArrayWarningPng,
  shadowUrl: '',
});

export const iconStationArrayUnhealthy = new Icon({
  iconSize: [32, 32],
  tooltipAnchor: [0, 0],
  iconRetinaUrl: stationArrayUnhealthyPng,
  iconUrl: stationArrayUnhealthyPng,
  shadowUrl: '',
});

export const iconStationArrayTriggered = new Icon({
  iconSize: [48, 48],
  tooltipAnchor: [0, 0],
  iconRetinaUrl: stationArrayTriggeredPng,
  iconUrl: stationArrayTriggeredPng,
  shadowUrl: '',
});

export const iconStationNode = new Icon({
  iconSize: [32, 32],
  tooltipAnchor: [0, 0],
  iconRetinaUrl: stationNodeDefaultPng,
  iconUrl: stationNodeDefaultPng,
  shadowUrl: '',
});

export const iconStationNodeWarning = new Icon({
  iconSize: [32, 32],
  tooltipAnchor: [0, 0],
  iconRetinaUrl: stationNodeWarningPng,
  iconUrl: stationNodeWarningPng,
  shadowUrl: '',
});

export const iconStationNodeUnhealthy = new Icon({
  iconSize: [32, 32],
  tooltipAnchor: [0, 0],
  iconRetinaUrl: stationNodeUnhealthyPng,
  iconUrl: stationNodeUnhealthyPng,
  shadowUrl: '',
});

export const iconStationNodeTriggered = new Icon({
  iconSize: [48, 48],
  tooltipAnchor: [0, 0],
  iconRetinaUrl: stationNodeTriggeredPng,
  iconUrl: stationNodeTriggeredPng,
  shadowUrl: '',
});

export const iconStationSingle = new Icon({
  iconSize: [32, 32],
  tooltipAnchor: [0, 0],
  iconRetinaUrl: stationSingleDefaultPng,
  iconUrl: stationSingleDefaultPng,
  shadowUrl: '',
});

export const iconStationSingleWarning = new Icon({
  iconSize: [32, 32],
  tooltipAnchor: [0, 0],
  iconRetinaUrl: stationSingleWarningPng,
  iconUrl: stationSingleWarningPng,
  shadowUrl: '',
});

export const iconStationSingleUnhealthy = new Icon({
  iconSize: [32, 32],
  tooltipAnchor: [0, 0],
  iconRetinaUrl: stationSingleUnhealthyPng,
  iconUrl: stationSingleUnhealthyPng,
  shadowUrl: '',
});

export const iconStationSingleTriggered = new Icon({
  iconSize: [48, 48],
  tooltipAnchor: [0, 0],
  iconRetinaUrl: stationSingleTriggeredPng,
  iconUrl: stationSingleTriggeredPng,
  shadowUrl: '',
});

export const StationTriggeredIcons: Map<StationType, Icon> = new Map([
  [StationType.SINGLE, iconStationSingleTriggered],
  [StationType.ARRAY, iconStationArrayTriggered],
  [StationType.NODE, iconStationNodeTriggered],
]);

export const StationArrayIcons: Map<StationHealthStatus, Icon> = new Map([
  [StationHealthStatus.HEALTHY, iconStationArray],
  [StationHealthStatus.UNHEALTHY, iconStationArrayUnhealthy],
  [StationHealthStatus.WARNING, iconStationArrayWarning],
]);

export const StationNodeIcons: Map<StationHealthStatus, Icon> = new Map([
  [StationHealthStatus.HEALTHY, iconStationNode],
  [StationHealthStatus.UNHEALTHY, iconStationNodeUnhealthy],
  [StationHealthStatus.WARNING, iconStationNodeWarning],
]);

export const StationSingleIcons: Map<StationHealthStatus, Icon> = new Map([
  [StationHealthStatus.HEALTHY, iconStationSingle],
  [StationHealthStatus.UNHEALTHY, iconStationSingleUnhealthy],
  [StationHealthStatus.WARNING, iconStationSingleWarning],
]);

export const iconEpicenter = new Icon({
  iconSize: [48, 48],
  tooltipAnchor: [0, 0],
  iconRetinaUrl: epicenterPng,
  iconUrl: epicenterPng,
  shadowUrl: '',
});

export const iconCatalogLocation = new Icon({
  iconSize: [32, 32],
  tooltipAnchor: [0, 0],
  iconRetinaUrl: catalogLocationPng,
  iconUrl: catalogLocationPng,
  shadowUrl: '',
});

export const iconPoiMap: Map<AlertLevelType, Icon> = new Map([
  [
    AlertLevelType.WEAK,
    new Icon({
      iconSize: [48, 48],
      tooltipAnchor: [0, 0],
      iconRetinaUrl: poiWeakPng,
      iconUrl: poiWeakPng,
      shadowUrl: '',
    }),
  ],
  [
    AlertLevelType.MEDIUM,
    new Icon({
      iconSize: [48, 48],
      tooltipAnchor: [0, 0],
      iconRetinaUrl: poiMediumPng,
      iconUrl: poiMediumPng,
      shadowUrl: '',
    }),
  ],
  [
    AlertLevelType.STRONG,
    new Icon({
      iconSize: [48, 48],
      tooltipAnchor: [0, 0],
      iconRetinaUrl: poiStrongPng,
      iconUrl: poiStrongPng,
      shadowUrl: '',
    }),
  ],
]);

export const iconPoi = new Icon({
  iconSize: [48, 48],
  tooltipAnchor: [0, 0],
  iconRetinaUrl: poiDefaultPng,
  iconUrl: poiDefaultPng,
  shadowUrl: '',
});

import { Module } from 'vuex';
import { actions } from '@/store/filters-realtime-channels/actions';
import { getters } from '@/store/filters-realtime-channels/getters';
import { mutations } from '@/store/filters-realtime-channels/mutations';
import { state } from '@/store/filters-realtime-channels/state';
import { RootState } from '@/models/states/root-state.model';
import { FiltersRealtimeChannelsState } from '@/models/states/filters-state.model';

const namespaced: boolean = true;

export const filtersRealtimeChannels: Module<FiltersRealtimeChannelsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

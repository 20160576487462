import moment from 'moment-timezone';
import { FiltersState } from '@/models/states/filters-state.model';
import { MESSAGE_TYPES } from '@/constants/message-types.constant';
import { DATE_HOUR_FORMAT } from '@/constants/app.constants';
import { FILTER_TYPE, UNIT } from '@/constants/readings.constants';

const now = moment();
const timeStamp = now.clone().subtract(300, 'seconds');

export const state: FiltersState = {
  filters: {
    bpFilter: true,
    channels: [3, 4, 5],
    date: now.toISOString().substr(0, 10),
    displayInterval: 300,
    itemsPerPage: 15,
    messageTypes: [MESSAGE_TYPES.P_DETECTED, MESSAGE_TYPES.S_DETECTED, MESSAGE_TYPES.SOURCE, MESSAGE_TYPES.EVENT_ENDED],
    nodes: [0],
    page: 1,
    time: timeStamp.format(DATE_HOUR_FORMAT),
    stationIds: null,
    unixTimestamp: timeStamp.valueOf(),
    unit: UNIT.SENSOR_SPECIFIC,
    filterType: FILTER_TYPE.REMOVE_MEAN,
  },
};

export enum StationType {
  SINGLE = 'SINGLE',
  ARRAY = 'ARRAY',
  NODE = 'NODE', // only for frontend purposes
}

export enum StationHealthStatus {
  HEALTHY = 'HEALTHY',
  UNHEALTHY = 'UNHEALTHY',
  WARNING = 'WARNING',
}

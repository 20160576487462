import { FiltersEventsState } from '@/models/states/filters-state.model';
import { MESSAGE_TYPES } from '@/constants/message-types.constant';
import { FILTER_TYPE, UNIT } from '@/constants/readings.constants';

export const state: FiltersEventsState = {
  filters: {
    bpFilter: true,
    channels: [0, 1, 2, 3, 4, 5],
    endDate: 0,
    messageTypes: [
      MESSAGE_TYPES.P_DETECTED,
      MESSAGE_TYPES.P_SLOBAZ_DETERMINED,
      MESSAGE_TYPES.S_DETECTED,
      MESSAGE_TYPES.EVENT_ENDED,
      MESSAGE_TYPES.REGIONAL_EVENT_ENDED,
      MESSAGE_TYPES.VIDA,
    ],
    nodes: [0],
    startDate: 0,
    unit: UNIT.SENSOR_SPECIFIC,
    filterType: FILTER_TYPE.REMOVE_MEAN,
  },
};

import { ROLES } from '@/constants/app.constants';
import { keycloak } from '@/plugins/keycloak-vue';

class PermissionsService {
  public canRunSimulations(): boolean {
    return keycloak.hasRealmRole(ROLES.ROLE_SIMULATION_RUNNER);
  }

  public isAdmin(): boolean {
    return keycloak.hasRealmRole(ROLES.ROLE_ADMIN);
  }

  public isPublisher(): boolean {
    return keycloak.hasRealmRole(ROLES.ROLE_ADMIN) || keycloak.hasRealmRole(ROLES.ROLE_BLOG_EDITOR);
  }
}

export const Permissions = new PermissionsService();

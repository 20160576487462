import { MutationTree } from 'vuex';
import * as ls from 'local-storage';
import { FiltersRealtime, FiltersRealtimeState } from '@/models/states/filters-state.model';
import { STORAGE_REALTIME_FILTERS } from '@/constants/app.constants';

export const mutations: MutationTree<FiltersRealtimeState> = {
  onUpdateFilters(state, payload: FiltersRealtime) {
    state.filters = payload;
    ls.set<FiltersRealtime>(STORAGE_REALTIME_FILTERS, state.filters);
  },
};

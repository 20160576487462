import Vue from 'vue';
import { MutationTree } from 'vuex';
import * as ls from 'local-storage';
import { STORAGE_OPEN_PANELS } from '@/constants/app.constants';
import { OpenPanelsState } from '@/models/states/open-panels-state';

export const mutations: MutationTree<OpenPanelsState> = {
  saveRegionalEventOpenPanels(state: OpenPanelsState, payload: { eventId: string; openPanels: number[] }) {
    Vue.set(state.regionalEvent, payload.eventId, payload.openPanels);
    ls.set<OpenPanelsState>(STORAGE_OPEN_PANELS, state);
  },

  saveEventOpenPanels(state: OpenPanelsState, payload: { eventId: string; openPanels: number[] }) {
    Vue.set(state.event, payload.eventId, payload.openPanels);
    ls.set<OpenPanelsState>(STORAGE_OPEN_PANELS, state);
  },
};

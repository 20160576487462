import axios from 'axios';

class SessionService {
  public token: string = '';
  public customerId: string | null = null;

  public setToken(token: string | undefined): void {
    if (!token) {
      return;
    }
    this.token = token;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  public setCustomerId(customerId: string) {
    this.customerId = customerId;
  }

  public getCustomerId(): string | null {
    return this.customerId;
  }
}

export const Session = new SessionService();

import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import Vue from 'vue';
import { double, email, max, numeric, regex, required } from 'vee-validate/dist/rules';

// No message specified.
extend('email', email);

// Override the default message.
extend('required', required);
extend('max', max);
extend('numeric', numeric);
extend('regex', regex);
extend('double', double);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import { PointOfInterestState } from '@/models/states/point-of-interest-state';

export const state: PointOfInterestState = {
  isLoading: false,
  queryError: false,
  saveError: false,
  saveSuccessful: false,
  pointsOfInterest: [],
  currentId: -1,
};

import { GetterTree } from 'vuex';
import { RootState } from '@/models/states/root-state.model';
import { OpenPanelsState } from '@/models/states/open-panels-state';

export const getters: GetterTree<OpenPanelsState, RootState> = {
  getRegionalEventOpenPanels(state): any {
    return state.regionalEvent;
  },

  getEventOpenPanels(state): any {
    return state.event;
  },
};

import { FiltersEventsRegionalState } from '@/models/states/filters-state.model';
import { FILTER_TYPE, UNIT } from '@/constants/readings.constants';

export const state: FiltersEventsRegionalState = {
  filters: {
    bpFilter: true,
    endDate: 0,
    observedTime: 0,
    startDate: 0,
    unit: UNIT.SENSOR_SPECIFIC,
    filterType: FILTER_TYPE.REMOVE_MEAN,
  },
};

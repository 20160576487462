import { Module } from 'vuex';
import { actions } from '@/store/filters-simulation-analysis/actions';
import { getters } from '@/store/filters-simulation-analysis/getters';
import { mutations } from '@/store/filters-simulation-analysis/mutations';
import { state } from '@/store/filters-simulation-analysis/state';
import { RootState } from '@/models/states/root-state.model';
import { FiltersSimulationAnalysisState } from '@/models/states/filters-state.model';

const namespaced: boolean = true;

export const filtersSimulationAnalysis: Module<FiltersSimulationAnalysisState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

import axios from 'axios';
import _ from 'lodash';
import { ReadingsRequest, SOHQueryResponse, StationHealthQueryResponse, StationResponse } from '@/models/response.model';
import { Station, StationHealth } from '@/models/station.model';
import { Customer } from '@/models/customer.model';

export default class CustomerService {
  public static query(): Promise<Customer[]> {
    return axios.get<Customer[]>(`/customers`).then((response) => _.get(response.data, 'customers', []));
  }
}

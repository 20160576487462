import _ from 'lodash';
import { GetterTree } from 'vuex';
import { RootState } from '@/models/states/root-state.model';
import { Alert, AlertsState } from '@/models/states/alerts-state.model';

export const getters: GetterTree<AlertsState, RootState> = {
  getAlerts(state): Alert[] {
    return _.cloneDeep(state.alerts);
  },
};

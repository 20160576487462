import moment from 'moment-timezone';
import _ from 'lodash';
import { ActionTree } from 'vuex';
import { RootState } from '@/models/states/root-state.model';
import { Alert, AlertsState } from '@/models/states/alerts-state.model';

export const actions: ActionTree<AlertsState, RootState> = {
  addAlert({ commit }, payload: Alert): any {
    const id = moment().valueOf();
    commit(
      'onAddAlert',
      _.extend({}, payload, {
        id,
      }),
    );

    if (payload.timeout) {
      setTimeout(() => {
        commit('removeAlert', id);
      }, payload.timeout);
    }
  },

  clearAlerts({ commit }): any {
    commit('onClearAlerts');
  },
};

import { Module } from 'vuex';
import { actions } from '@/store/open-panels/actions';
import { getters } from '@/store/open-panels/getters';
import { mutations } from '@/store/open-panels/mutations';
import { state } from '@/store/open-panels/state';
import { RootState } from '@/models/states/root-state.model';
import { OpenPanelsState } from '@/models/states/open-panels-state';

const namespaced: boolean = true;

export const openPanels: Module<OpenPanelsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

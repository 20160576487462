import _ from 'lodash';
import { GetterTree } from 'vuex';
import { RootState } from '@/models/states/root-state.model';
import { FiltersSeismograms, FiltersState } from '@/models/states/filters-state.model';

export const getters: GetterTree<FiltersState, RootState> = {
  getFilters(state): FiltersSeismograms {
    return _.cloneDeep(state.filters);
  },
};

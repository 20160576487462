import { ActionTree } from 'vuex';
import { RootState } from '@/models/states/root-state.model';
import {
  FiltersRealtimeChannels,
  FiltersRealtimeChannelsState,
} from '@/models/states/filters-state.model';

export const actions: ActionTree<FiltersRealtimeChannelsState, RootState> = {
  updateFilters({ commit }, payload: FiltersRealtimeChannels): any {
    commit('onUpdateFilters', payload);
  },
};

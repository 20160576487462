import { GetterTree } from 'vuex';
import { ReadingsState } from '@/models/states/readings-state.model';
import { RootState } from '@/models/states/root-state.model';

export const getters: GetterTree<ReadingsState, RootState> = {
  getData(state): number[][] {
    return state.data;
  },
  // getTimeSeriesData(state): number[][] {
  //   return state.data.map((reading) => [reading.time, reading.value]);
  // },
};

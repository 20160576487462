import { Options } from '@/models/app.model';

export const UNIT = {
  SENSOR_SPECIFIC: 'SENSOR_SPECIFIC',
  VOLTS: 'VOLTS',
  METERS_PER_SECOND_SQUARED: 'METERS_PER_SECOND_SQUARED',
  CENTIMETERS_PER_SECOND: 'CENTIMETERS_PER_SECOND',
};

export const UNITS: Options<string>[] = [
  { name: 'Sensor Native (cm/s or m/s²)', value: UNIT.SENSOR_SPECIFIC, unit: 'cm/s' },
  { name: 'Raw', value: UNIT.VOLTS, unit: 'V' },
  { name: 'Acceleration (m/s²)', value: UNIT.METERS_PER_SECOND_SQUARED, unit: 'm/s²' },
  { name: 'Acceleration (m/s)', value: UNIT.CENTIMETERS_PER_SECOND, unit: 'cm/s', hidden: true },
];

export const FILTER_TYPE = {
  NONE: 'NONE',
  REMOVE_MEAN: 'REMOVE_MEAN',
  BUTTERWORTH_BANDPASS: 'BUTTERWORTH_BANDPASS',
};

export const FILTER_TYPES: (low: number, high: number) => Options<string>[] = (low: number = 1, high: number = 10) => [
  { name: 'None', value: FILTER_TYPE.NONE },
  { name: 'Remove Mean', value: FILTER_TYPE.REMOVE_MEAN },
  { name: `Band Pass (${low} - ${high}Hz)`, value: FILTER_TYPE.BUTTERWORTH_BANDPASS },
];

import axios from 'axios';
import _ from 'lodash';
import { PointOfInterest, PointOfInterestComplete } from '@/models/position.model';
import { MessageSource } from '@/models/message.model';
import { getPoiColorFromType } from '@/filters/status.filter';

export default class POIService {
  public static query(): Promise<PointOfInterest[]> {
    return axios
      .get<PointOfInterest[]>(`/pois`)
      .then((response) => response.data)
      .then((response) => _.get(response, 'elements', []));
  }

  public static find(poiId: number): Promise<PointOfInterest> {
    return axios.get<PointOfInterest>(`/pois/${poiId}`).then((response) => response.data);
  }

  public static remove(poiId: number): Promise<PointOfInterest> {
    return axios.delete<PointOfInterest>(`/pois/${poiId}`).then((response) => response.data);
  }

  public static save(pointOfInterest: PointOfInterest): Promise<PointOfInterest> {
    return axios.post<PointOfInterest>(`/pois`, pointOfInterest).then((response) => response.data);
  }

  public static setPointsOfInterestData(currentPointsOfInterest: PointOfInterest[], sourceMessage: MessageSource): PointOfInterestComplete[] {
    return _(currentPointsOfInterest)
      .map((poi) => _.extend({}, poi, _.find(sourceMessage.pointsOfInterest, { id: poi.id })))
      .map((poi) =>
        _.extend({}, poi, {
          color: getPoiColorFromType(poi?.triggeredRule ? poi.triggeredRule.pgaThresholdType : ''),
        }),
      )
      .sortBy(['distanceToHypocenterKm'])
      .value();
  }
}

import Vue from 'vue';
// @ts-ignore
import VueAnalytics from 'vue-ua';
import VueRouter, { RouteConfig } from 'vue-router';
import { rulesGuard, simulationGuard, stationConfigurationGuard } from '@/router/guards';

Vue.use(VueRouter);

export const routes: RouteConfig[] = [
  {
    name: 'home',
    path: '/',
    redirect: 'real-time-monitor',
  },
  {
    name: 'real-time-monitor',
    path: '/real-time-monitor',
    meta: {
      title: 'Real Time Monitor',
      icon: 'mdi-map',
    },
    component: () => import(/* webpackChunkName: "real-time-monitor" */ '../views/real-time-monitor/RealTimeMonitor.view.vue'),
  },
  {
    name: 'real-time-readings-monitor',
    path: '/real-time-readings-monitor',
    meta: {
      title: 'Real Time Readings Monitor',
      icon: 'mdi-view-dashboard',
    },
    component: () => import(/* webpackChunkName: "real-time-monitor" */ '../views/real-time-monitor/RealTimeReadingsMonitor.view.vue'),
  },
  {
    name: 'real-time-channel-monitor',
    path: '/real-time-channel-monitor/:stationId',
    props: true,
    meta: {
      title: 'Real Time Node Monitor',
      icon: 'mdi-view-dashboard',
    },
    component: () => import(/* webpackChunkName: "real-time-monitor" */ '../views/real-time-monitor/RealTimeChannelMonitor.view.vue'),
  },
  {
    name: 'events-list',
    path: '/events',
    props: true,
    meta: {
      title: 'Events',
      icon: 'mdi-merge',
    },
    component: () => import(/* webpackChunkName: "events" */ '../views/events/EventsList.view.vue'),
  },
  {
    name: 'event-details',
    path: '/events/:eventId',
    props: true,
    meta: {
      title: 'Event Details',
      icon: 'mdi-merge',
    },
    component: () => import(/* webpackChunkName: "events" */ '../views/events/EventDetails.view.vue'),
  },
  {
    name: 'event-regional',
    path: '/events/:eventId/regional',
    props: true,
    meta: {
      title: 'Regional Event Details',
      icon: 'mdi-merge',
    },
    component: () => import(/* webpackChunkName: "events" */ '../views/events/EventRegional.view.vue'),
  },
  {
    name: 'seismograms',
    path: '/seismograms',
    props: true,
    meta: {
      title: 'Seismograms',
      icon: 'mdi-chart-areaspline',
    },
    redirect: {
      name: 'seismograms.readings',
    },
    component: () => import(/* webpackChunkName: "seismograms" */ '../views/seismograms/Seismograms.view.vue'),
    children: [
      {
        name: 'seismograms.readings',
        path: 'readings',
        meta: {
          title: 'Seismograms Readings',
          icon: 'mdi-chart-areaspline',
        },
        component: () => import(/* webpackChunkName: "seismograms" */ '../views/seismograms/SeismogramsReadings.view.vue'),
      },
      {
        name: 'seismograms.messages',
        path: 'messages',
        meta: {
          title: 'Seismograms Messages',
          icon: 'mdi-chart-areaspline',
        },
        component: () => import(/* webpackChunkName: "seismograms" */ '../views/seismograms/SeismogramsMessages.view.vue'),
      },
      {
        name: 'seismograms.map',
        path: 'map',
        meta: {
          title: 'Seismograms Map',
          icon: 'mdi-chart-areaspline',
        },
        component: () => import(/* webpackChunkName: "seismograms" */ '../views/seismograms/SeismogramsMap.view.vue'),
      },
    ],
  },
  {
    name: 'stations',
    path: '/stations',
    meta: {
      title: 'Stations Management',
      shortTitle: 'Management',
      icon: 'mdi-star-three-points-outline',
    },
    component: () => import(/* webpackChunkName: "stations" */ '../views/station-management/StationManagement.view.vue'),
  },
  {
    name: 'stations-new',
    path: '/stations/new',
    meta: {
      title: 'New Station',
      icon: 'mdi-star-three-points-outline',
    },
    component: () => import(/* webpackChunkName: "stations" */ '../views/station-management/StationManagementDetails.view.vue'),
  },
  {
    name: 'stations-edit',
    path: '/stations/:stationId',
    props: true,
    meta: {
      title: 'Station',
      icon: 'mdi-star-three-points-outline',
    },
    component: () => import(/* webpackChunkName: "stations" */ '../views/station-management/StationManagementDetails.view.vue'),
  },
  {
    name: 'station-monitor',
    path: '/station-monitor',
    meta: {
      title: 'Station Health',
      shortTitle: 'Health',
      icon: 'mdi-power-plug',
    },
    component: () => import(/* webpackChunkName: "station-monitor" */ '../views/station-monitor/StationMonitor.view.vue'),
  },
  {
    name: 'single-stations-monitor',
    path: '/single-stations-monitor',
    meta: {
      title: 'Single Stations Health',
      shortTitle: 'Singles Health',
      icon: 'mdi-power-plug-outline',
    },
    component: () => import(/* webpackChunkName: "station-monitor" */ '../views/station-monitor/StationSingleHealth.view.vue'),
  },
  {
    name: 'station-details',
    path: '/station-details/:stationId',
    props: true,
    meta: {
      title: 'Station Details',
      icon: 'mdi-power-plug',
    },
    component: () => import(/* webpackChunkName: "station-monitor" */ '../views/station-monitor/StationDetails.view.vue'),
  },
  {
    name: 'points-of-interest',
    path: '/points-of-interest',
    props: true,
    meta: {
      title: 'Points Of Interest',
      icon: 'mdi-map-marker-plus',
    },
    component: () => import(/* webpackChunkName: "account" */ '../views/account/PointsOfInterest.view.vue'),
  },
  {
    beforeEnter: rulesGuard,
    name: 'rules-list',
    path: '/rules',
    props: true,
    meta: {
      title: 'Alerting Rules',
      icon: 'mdi-account-alert',
    },
    component: () => import(/* webpackChunkName: "account" */ '../views/account/RulesList.view.vue'),
  },
  {
    beforeEnter: rulesGuard,
    name: 'rules-details',
    path: '/rules/:ruleId',
    props: true,
    meta: {
      title: 'Alerting Rule',
      icon: 'mdi-account-alert',
    },
    component: () => import(/* webpackChunkName: "account" */ '../views/account/RulesDetails.view.vue'),
  },
  {
    beforeEnter: rulesGuard,
    name: 'rules-clone',
    path: '/rules/:ruleId/clone',
    props: true,
    meta: {
      title: 'Alerting Rule',
      icon: 'mdi-account-alert',
    },
    component: () => import(/* webpackChunkName: "account" */ '../views/account/RulesDetails.view.vue'),
  },
  {
    beforeEnter: simulationGuard,
    name: 'simulation-list',
    path: '/simulations',
    meta: {
      title: 'Simulations',
      icon: 'mdi-math-compass',
    },
    component: () => import(/* webpackChunkName: "simulations" */ '../views/simulations/SimulationsList.view.vue'),
  },
  {
    beforeEnter: simulationGuard,
    name: 'simulation-details',
    path: '/simulations/:simulationId',
    props: true,
    meta: {
      title: 'Simulation Details',
      icon: 'mdi-math-compass',
    },
    component: () => import(/* webpackChunkName: "simulations" */ '../views/simulations/SimulationDetails.view.vue'),
  },
  {
    beforeEnter: simulationGuard,
    name: 'simulation-clone',
    path: '/simulations/:simulationId/clone',
    props: true,
    meta: {
      title: 'Clone Simulation Details',
      icon: 'mdi-math-compass',
    },
    component: () => import(/* webpackChunkName: "simulations" */ '../views/simulations/SimulationDetails.view.vue'),
  },
  {
    beforeEnter: simulationGuard,
    name: 'simulation-analysis',
    path: '/simulations/:simulationId/analysis',
    props: true,
    meta: {
      title: 'Simulation Analysis',
      icon: 'mdi-math-compass',
    },
    component: () => import(/* webpackChunkName: "simulations" */ '../views/simulations/SimulationAnalysis.view.vue'),
  },
  {
    beforeEnter: simulationGuard,
    name: 'simulation-run-configuration',
    path: '/simulations/:simulationId/run/:runId',
    props: true,
    meta: {
      title: 'Simulation Run Configuration',
      icon: 'mdi-math-compass',
    },
    component: () => import(/* webpackChunkName: "simulations" */ '../views/simulations/SimulationRun.view.vue'),
  },
  {
    beforeEnter: simulationGuard,
    name: 'simulation-run-configuration-clone',
    path: '/simulations/:simulationId/run/:runId/clone',
    props: true,
    meta: {
      title: 'Clone Simulation Run Configuration',
      icon: 'mdi-math-compass',
    },
    component: () => import(/* webpackChunkName: "simulations" */ '../views/simulations/SimulationRun.view.vue'),
  },
  {
    beforeEnter: stationConfigurationGuard,
    name: 'station-configuration-history',
    path: '/station-configuration-history',
    props: true,
    meta: {
      title: 'Station Configuration History',
      shortTitle: 'Configuration History',
      icon: 'mdi-desktop-tower',
    },
    component: () => import(/* webpackChunkName: "station-configuration" */ '../views/station-configuration/StationConfigurationHistory.view.vue'),
  },
  {
    beforeEnter: stationConfigurationGuard,
    name: 'service-versions',
    path: '/service-versions',
    props: true,
    meta: {
      title: 'Service Versions',
      icon: 'mdi-pickaxe',
    },
    component: () => import(/* webpackChunkName: "station-configuration" */ '../views/station-configuration/ServiceVersions.view.vue'),
  },
  {
    beforeEnter: stationConfigurationGuard,
    name: 'station-service-configuration',
    path: '/station-service-configuration',
    props: true,
    meta: {
      title: 'Station Service Configuration Files',
      shortTitle: 'Service Config Files',
      icon: 'mdi-hexagon-multiple',
    },
    component: () =>
      import(/* webpackChunkName: "station-configuration" */ '../views/station-configuration/StationServiceConfigurationFiles.view.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

Vue.use(VueAnalytics, {
  appName: 'SeismicAI', // Mandatory
  appVersion: '1.0.0', // Mandatory
  trackingId: 'UA-161623635-1', // Mandatory
  debug: false, // Whether display console logs debugs (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
});

export default router;

import _ from 'lodash';
import { GetterTree } from 'vuex';
import { RootState } from '@/models/states/root-state.model';
import {
  FiltersRealtimeChannels,
  FiltersRealtimeChannelsState,
} from '@/models/states/filters-state.model';

export const getters: GetterTree<FiltersRealtimeChannelsState, RootState> = {
  getFilters(state): FiltersRealtimeChannels {
    return _.cloneDeep(state.filters);
  },
};

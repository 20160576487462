import { MEDIUM_COLOR, STRONG_COLOR, WEAK_COLOR } from '@/constants/app.constants';
import { StationHealthStatus, StationType } from '@/constants/station.constants';
import { iconPoi, iconPoiMap, StationArrayIcons, StationNodeIcons, StationSingleIcons, StationTriggeredIcons } from '@/constants/leaflet.constants';
import { AlertLevelType } from '@/constants/alert-level.constants';
import { Icon } from 'leaflet';

export function getIconFromStatus(status: string): string {
  if (status === StationHealthStatus.WARNING) {
    return 'mdi-cloud-alert';
  }
  if (status === StationHealthStatus.UNHEALTHY) {
    return 'mdi-cloud-lock';
  }
  return 'mdi-cloud-check';
}

export function getColorFromStatus(status: string): string {
  if (status === StationHealthStatus.WARNING) {
    return 'orange lighten-2';
  }
  if (status === StationHealthStatus.UNHEALTHY) {
    return 'red darken-1';
  }
  return 'light-green lighten-1';
}

export function getIconForStatus(status: StationHealthStatus, isNode: boolean = false, type: StationType, isStationImpacted: boolean) {
  if (isStationImpacted) {
    return isNode ? StationTriggeredIcons.get(StationType.NODE) : StationTriggeredIcons.get(type);
  }

  if (type === StationType.SINGLE) {
    return StationSingleIcons.get(status);
  }

  if (type === StationType.ARRAY && !isNode) {
    return StationArrayIcons.get(status);
  }

  if (type === StationType.ARRAY && isNode) {
    return StationNodeIcons.get(status);
  }

  return isNode ? StationNodeIcons.get(StationHealthStatus.HEALTHY) : StationArrayIcons.get(StationHealthStatus.HEALTHY);
}

export function getPoiIconFromType(type: AlertLevelType | null): Icon {
  return type ? iconPoiMap.get(type) ?? iconPoi : iconPoi;
}

export function getPoiColorFromType(type: string) {
  if (type === 'WEAK') {
    return WEAK_COLOR;
  }
  if (type === 'MEDIUM') {
    return MEDIUM_COLOR;
  }
  if (type === 'STRONG') {
    return STRONG_COLOR;
  }
  return '';
}
